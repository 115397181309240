import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Filtro } from '../models/filtro.model';
import { ModeloTrabalho } from '@shared/service-proxies/service-proxies';


@Injectable({ providedIn: 'root' })

export class FiltroService {

    private filtro: Filtro;
    private filtroUpdated = new Subject<Filtro>();

    getFiltro() {
        return this.filtro;
    }

    getFiltroUpdateListener() {
        return this.filtroUpdated.asObservable();
    }

    setPcd(pcd: boolean){
        if (!this.filtro) this.filtro = { pcd };
        this.filtro.pcd = pcd;
        this.filtroUpdated.next(this.filtro);
    }

    setEstado(estado: string) {
        if (!this.filtro) this.filtro = { cidade: null, estado };
        if (!estado) this.filtro.cidade = null;

        this.filtro.estado = estado;
        this.filtroUpdated.next(this.filtro);
    }

    setCidade(cidade: string) {
        this.filtro.cidade = cidade;
        this.filtroUpdated.next(this.filtro);
    }

    setCargos(cargosId: string[]) {
        if (!this.filtro) this.filtro = { cargosId };
        this.filtro.cargosId = cargosId;
        this.filtroUpdated.next(this.filtro);
    }

    setSalario(salario: number) {
        if (!this.filtro) this.filtro = { salario };
        this.filtro.salario = salario;
        this.filtroUpdated.next(this.filtro);
    }

    setModelo(modeloTrabalho: ModeloTrabalho) {
        if (!this.filtro) this.filtro = { modeloTrabalho };
        this.filtro.modeloTrabalho = modeloTrabalho;
        this.filtroUpdated.next(this.filtro);
    }

    setTextoBusca(textoBusca: string) {
        if (!this.filtro) this.filtro = { textoBusca };
        this.filtro.textoBusca = textoBusca;
        this.filtroUpdated.next(this.filtro);
    }

    setOdenacao(ordenacao: string) {
        if (!this.filtro) this.filtro = { ordenacao };
        this.filtro.ordenacao = ordenacao;
        this.filtroUpdated.next(this.filtro);
    }

    setNomeEmpresa(nomeEmpresa:string)
    {
        if (!this.filtro) this.filtro = { nomeEmpresa };
        this.filtro.nomeEmpresa = nomeEmpresa;
        this.filtroUpdated.next(this.filtro);
    }
}
