<div class="row">

    <div class="col-12 col-md-4 col-lg-3 align-items-stretch px-10 mb-5 filtro">
        <filtro-vitrine></filtro-vitrine>
    </div>

    <div id="vagas-contianer" class="col-12 col-md-8 col-lg-9 px-15">

        <h1 class="title-2">Empresas Contratantes</h1>

        <card-ordenacao-vaga></card-ordenacao-vaga>

        <div *ngFor="let vaga of listaVagas | paginate : {
          id: 'server',
          itemsPerPage: pageSize,
          currentPage: pageNumber,
          totalItems: totalItems
        }">
            <card-candidato-vaga [vaga]="vaga" [isInscrito]="isInscrito(vaga)"></card-candidato-vaga>
        </div>

        <div class="row d-flex justify-content-end">

            <div class="col-sm-4 col-12">
                <div class="float-sm-right m-auto">
                    <abp-pagination-controls id="server" (pageChange)="getDataPage($event)">
                    </abp-pagination-controls>
                </div>
            </div>
        </div>

    </div>
</div>