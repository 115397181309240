import { Component, Injector, OnInit } from "@angular/core";
import { AppComponentBase } from "@shared/app-component-base";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { AppAuthService } from "@shared/auth/app-auth.service";
import { ForgotPasswordDialogComponent } from "../forgot-password/forgot-password-dialog.component";
import { Router } from "@angular/router";
import { CreateAccountDialogComponent } from "initial/create-account-dialog/create-account-dialog.component";
import { CandidatoDto, CandidatoServiceProxy, CandidatoVagaServiceProxy, CreateCandidatoVagaDto, VagaDto } from "@shared/service-proxies/service-proxies";
import { RoleSelectionDialogComponent } from "initial/role-selection-dialog/role-selection-dialog.component";
import { Observable, finalize } from "rxjs";
import { map } from 'rxjs/operators';
import { CandidatoService } from "candidato/service/candidato.service";

@Component({
  selector: "app-login-dialog",
  templateUrl: "./login-dialog.component.html",
  styleUrls: ["./login-dialog.component.scss"],
})
export class LoginDialogComponent extends AppComponentBase implements OnInit {
  public submitting = false;
  public showPassword: boolean = false;
  public candidato: CandidatoDto;
  public vaga: VagaDto;
  public openedByDialogCandidatarVaga: boolean;
  public openedByLogin: boolean;
  constructor(
    public injector: Injector,
    private _candidatoVagaService: CandidatoVagaServiceProxy,
    private _candidatoService: CandidatoServiceProxy,
    private _modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public authService: AppAuthService,
    private _router: Router,
    private serviceCanditadoOptions: CandidatoService,

  ) {
    super(injector);
  }

  ngOnInit(): void { }

  login(): void {
    this.submitting = true;
    abp.ui.setBusy();
    this.authService.authenticate(() => {
      this.submitting = false;
      this.handleNavigation();
    });
  }

  handleNavigation(): void {
    if (!this.appSession.user) return;
    if (this.appSession.getRoles().length > 1) {
      if(this.openedByLogin){
        this.openRoleSelectionModal(this.appSession.user.roleNames);
      } else if (this.openedByDialogCandidatarVaga) {
        if (this.verificaCandidato(this.appSession.user.roleNames)) {
          this.candidatar();
          this.bsModalRef.hide();
        } else {
          abp.ui.clearBusy();
          this.notify.warn(this.l("É preciso que você tenha uma conta de candidato para candidatar-se à vaga"));
        }
      } else {
        let role = this.appSession.getRoles()[0];
        if (role.toUpperCase() !== "CANDIDATO") {
          abp.ui.clearBusy();
          this.notify.warn(this.l("É preciso que você tenha uma conta de candidato para candidatar-se à vaga"));
          this.logout();
        } else {
          this.candidatar();
          this.bsModalRef.hide();
        }
      }
    } else {
      if (this.openedByDialogCandidatarVaga) {
        let role = this.appSession.getRoles()[0];
        if (role.toUpperCase() !== "CANDIDATO") {
          abp.ui.clearBusy();
          this.notify.warn(this.l("É preciso que você tenha uma conta de candidato para candidatar-se à vaga"));
          this.logout();
        } else {
          this.candidatar();
          this.bsModalRef.hide();
        }
      }
      else {
        if (!this.appSession.user) return;
        if (this.appSession.getRoles().length > 1) {
          this.openRoleSelectionModal(this.appSession.user.roleNames);
        } else {
          let role = this.appSession.getRoles()[0];
          this.redirectByRole(role);
          abp.ui.clearBusy();
          this.bsModalRef.hide();
        }
      }
    }
  }

  private openRoleSelectionModal(roleNames: Array<string>): void {
    let roleSelectionDialog: BsModalRef;
    roleSelectionDialog = this._modalService.show(
      RoleSelectionDialogComponent,
      {
        class: "modal-dialog-centered",
        backdrop: true,
        ignoreBackdropClick: true,
      }
    );

    this.bsModalRef.hide();

    roleSelectionDialog.content.roleSelected.subscribe((role: string) => {
      this.redirectByRole(role);
    });
  }

  redirectByRole(role: string): void {
    role = role.toUpperCase();
    switch (role) {
      case "CANDIDATO":
        this._router.navigate(["/candidato"]);
        break;
      case "EMPRESA":
        this._router.navigate(["/empresa"]);
        break;
      default:
        this._router.navigate(["/administrativo"]);
        break;
    }
  }

  verificaCandidato(array) {
    for (let i = 0; i < array.length; i++) {
      if (array[i].includes("Candidato")) {
        return true;
      }
    }
    return false;
  }

  logout(): void {
    this.authService.logout(false);
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  forgotPassword() {
    this.bsModalRef.hide();
    this._modalService.show(ForgotPasswordDialogComponent);
  }

  createAccount() {
    this.bsModalRef.hide();
    this.openCreateAccountDialog();
  }


  private openCreateAccountDialog(): void {
    let createAccountDialog: BsModalRef;
    if (this.openedByDialogCandidatarVaga) {
      createAccountDialog = this._modalService.show(
        CreateAccountDialogComponent, {
        class: "modal-lg",
        ignoreBackdropClick: true,
        initialState: {
          openedByDialogCandidatarVaga: true,
          vaga: this.vaga,
        },
      }
      );
    } else {
      createAccountDialog = this._modalService.show(
        CreateAccountDialogComponent, {
        class: "modal-lg",
        ignoreBackdropClick: true,
      }
      );
    }
  }

  candidatar() {
    let createCandidatoVaga: CreateCandidatoVagaDto = new CreateCandidatoVagaDto();

    this.getCandidatoId().subscribe(candidato => {
      if (candidato.curriculoDadoPessoal != null || candidato.curriculoDadoPessoalAnexo != null) {
        createCandidatoVaga.candidatoId = candidato.id;

        createCandidatoVaga.vagaId = this.vaga.id;

        abp.ui.setBusy();
        this._candidatoVagaService.create(createCandidatoVaga)
          .pipe(
            finalize(() => {
              abp.ui.clearBusy();
            })
          )
          .subscribe({
            complete: () => {
              abp.notify.success(this.l(`Parabéns! Você se candidatou com sucesso à vaga ${this.vaga.tituloVaga}. Fique atento às atualizações e confira o status da sua inscrição na seção de "Inscrições". Boa sorte!`));
              this._router.navigate(["/candidato"]);
            },
            error: error => {
              this._router.navigate(["/candidato"]);
            }
          });
      }
      else {
        this._candidatoService.tentativaCandidatarVaga(this.vaga.id).pipe(
          finalize(() => {
            abp.ui.clearBusy();
          })
        ).subscribe(x => {
          this.candidato = x;
          this.serviceCanditadoOptions.setCandidato(this.candidato);
          abp.notify.warn(this.l('Antes de se candidatar à vaga, por favor, preencha seu currículo. Estamos ansiosos para saber mais sobre você!'));
          this._router.navigate(['/candidato/meu-curriculo']);
        });
      }
    });
  }


  getCandidatoId(): Observable<CandidatoDto> {
    return this._candidatoService.getAbp()
      .pipe(
        finalize(() => {
          abp.ui.clearBusy();
        }),
        map(response => response),
      );
  }
}
