import { Component, Injector, OnInit } from "@angular/core";
import { accountModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/app-component-base";
import { AccountServiceProxy, ForgotPasswordInput } from "@shared/service-proxies/service-proxies";
import { BsModalRef } from "ngx-bootstrap/modal";
import { finalize } from "rxjs/operators";

@Component({
  templateUrl: "./forgot-password-dialog.component.html",
  styleUrls: ["./forgot-password-dialog.component.scss"],
  animations: [accountModuleAnimation()],
})
export class ForgotPasswordDialogComponent extends AppComponentBase implements OnInit {
  model = new ForgotPasswordInput();
  senha: string;
  saving: boolean;

  constructor(
    injector: Injector,
    private _accountService: AccountServiceProxy,
    public bsModalRef: BsModalRef
  ) {
    super(injector);
  }

  ngOnInit() { }

  resetPassword() {
    this.saving = true;
    abp.ui.setBusy();
    this._accountService
      .forgotPassword(this.model)
      .pipe(
        finalize(() => {
          this.saving = false;
          abp.ui.clearBusy();
        })
      )
      .subscribe(() => {
        this.bsModalRef.hide();
        abp.message.success(this.l('VerifiqueEmailRecuperacaoSenha'), this.l('Sucesso'));
      });
  }
}
