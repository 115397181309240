import {
  ChangeDetectorRef,
  Component,
  Injector,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppComponentBase } from "@shared/app-component-base";
import { AppAuthService } from "@shared/auth/app-auth.service";
import { AbpValidationError } from "@shared/components/validation/abp-validation.api";
import {
  AuthenticateModel,
  CandidatoDto,
  CandidatoServiceProxy,
  CandidatoVagaServiceProxy,
  CreateCandidatoDto,
  CreateCandidatoVagaDto,
  CreateEmpresaDto,
  CreateLaudoMedicoDto,
  EmpresaServiceProxy,
  EnumServiceProxy,
  LaudoMedicoServiceProxy,
  VagaDto,
} from "@shared/service-proxies/service-proxies";
import { BuscarCnpjService } from "@shared/services/buscar-cnpj.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { finalize } from "rxjs/operators";

import { Subscription } from "rxjs";
import { PixelService } from "ngx-multi-pixel";
import { CandidatoService } from "candidato/service/candidato.service";
import { RendaBrutaFamiliarConst } from "../../shared/constant/renda-bruta-familiar-enum";

export enum UserTypes {
  Candidate = "CANDIDATE",
  Company = "COMPANY",
}
export enum ScreenNames {
  Selection = "SELECTION",
  Candidate = "CANDIDATE",
  Company = "COMPANY",
  VerifyToken = "TOKEN_VERIFICATION",
}

export enum ComoConheceuEnum {
  BuscaNaInternet = 'Busca na internet',
  AnunciosEmRedesSociais = 'Anúncios em redes sociais',
  AmigosFamiliares = 'Amigos/Familiares',
  Outros = 'Outros',
}
@Component({
  selector: "app-create-account-dialog",
  templateUrl: "./create-account-dialog.component.html",
  styleUrls: ["./create-account-dialog.component.scss"],
})
export class CreateAccountDialogComponent
  extends AppComponentBase
  implements OnInit {
  public screen: ScreenNames = ScreenNames.Selection;
  public saving: boolean = false;
  private authenticateModel: AuthenticateModel = new AuthenticateModel();
  private userType!: UserTypes;
  public createCandidatoDto: CreateCandidatoDto = new CreateCandidatoDto();
  public createLaudoMedicoDto: CreateLaudoMedicoDto =
    new CreateLaudoMedicoDto();
  public createEmpresaDto: CreateEmpresaDto = new CreateEmpresaDto();
  public aceitouTermos = false;
  public prestadoraSuzano = false;
  public cnpjFilled = false;
  public showPassword = false;
  public openedByDialogCandidatarVaga: boolean;
  public openedByDialogParaEmrpesas: boolean;
  public openedByDialogParaCadastro: boolean;
  public vaga: VagaDto;
  public candidato: CandidatoDto;
  public HowDidYouMeetSelected: ComoConheceuEnum;
  public showOtherInput: boolean = false;
  quantidadePessoaFamilia: number = undefined;
  quantidadePessoaFamiliaMaior5: number = undefined;
  exibirQuantidadePessoaFamiliaMaior5: boolean = false;
  laudo: any = undefined;

  HowDidYouMeetList: string[] = this.getComoConheceu();

  rendasBrutasFamiliaresEnum = RendaBrutaFamiliarConst;
  subscriptions: Subscription[] = [];
  emailPattern = "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$";
  beneficiarioSuzano: string = this.l("TextoBeneficiarioSuzano");
  pcdTitle: string = this.l(
    "Marque esta caixa se você é uma pessoa com deficiência."
  );

  passwordValidationErrors: Partial<AbpValidationError>[] = [
    {
      name: "pattern",
      localizationKey:
        "PasswordsMustBeAtLeast8CharactersContainLowercaseUppercaseSpecial",
    },
  ];

  constructor(
    public injector: Injector,
    public bsModalRef: BsModalRef,
    private _candidatoService: CandidatoServiceProxy,
    private _buscarCnpjService: BuscarCnpjService,
    private _empresaService: EmpresaServiceProxy,
    private _candidatoVagaService: CandidatoVagaServiceProxy,
    private _laudoMedicoService: LaudoMedicoServiceProxy,
    private _appAuthService: AppAuthService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private _enumService: EnumServiceProxy,
    private pixel: PixelService,
    private router: Router,
    private serviceCanditadoOptions: CandidatoService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.onConsent();
    if (this.openedByDialogCandidatarVaga === true) {
      this.screen = ScreenNames.Candidate;
      this.userType = UserTypes.Candidate;
    }else if(this.openedByDialogParaEmrpesas == true){
      this.screen = ScreenNames.Company;
      this.userType = UserTypes.Company;
    }else if(this.openedByDialogParaCadastro){
      this.screen = ScreenNames.Candidate;
      this.userType = UserTypes.Candidate;
    }
    this.cdr.markForCheck();
  }

  onConsent(): void {
    this.pixel.initialize();
    this.pixel.track("CompleteRegistration", {
      status: true,
    });
  }

  public get ScreenNames() {
    return ScreenNames;
  }

  public onOptionSelected(option: ScreenNames) {
    this.screen = option;
    if (this.screen === ScreenNames.Candidate)
      this.userType = UserTypes.Candidate;
    if (this.screen === ScreenNames.Company) this.userType = UserTypes.Company;
  }

  createCandidato() {
    if (this.verificarInputs()) {
      return;
    }
    this.saving = true;
    abp.ui.setBusy();
    if (this.aceitouTermos) {
      this.createCandidatoDto.aceitouTermos = true;
    }

    if (this.exibirQuantidadePessoaFamiliaMaior5)
      this.createCandidatoDto.quantidadePessoaFamilia =
        this.quantidadePessoaFamiliaMaior5;
    else
      this.createCandidatoDto.quantidadePessoaFamilia =
        this.quantidadePessoaFamilia;

    this._candidatoService
      .create(this.createCandidatoDto)
      .pipe(
        finalize(() => {
          this.saving = false;
          abp.ui.clearBusy();
        })
      )
      .subscribe((x) => {
        this.candidato = x;
        this.notify.info(this.l("SavedSuccessfully"));
        this.authenticateModel.userNameOrEmailAddress =
          this.createCandidatoDto.email;
        this.authenticateModel.password = this.createCandidatoDto.senha;
        if (
          this.createCandidatoDto.pcd &&
          this.createCandidatoDto.pcd == true
        ) {
          this.createLaudoMedico(this.candidato.id);
        }
        this.doLogin();
      });
  }

  createLaudoMedico(idCandidato) {
    this.createLaudoMedicoDto.candidatoId = idCandidato;
    this._laudoMedicoService
      .create(this.createLaudoMedicoDto)
      .pipe(
        finalize(() => {
          abp.ui.clearBusy();
          this.saving = false;
        })
      )
      .subscribe((response) => {
        this.candidato.laudoMedico = response;
        abp.ui.clearBusy();
        this.cdr.markForCheck();
      });
  }

  createEmpresa() {
    if (!this.aceitouTermos) {
      this.notify.error(
        "Atenção: Aceite a Política de Privacidade e os Termos de Uso!"
      );
      return;
    }
    if(this.prestadoraSuzano && this.createEmpresaDto.areaAtuacaoEmpresaEnum == undefined){
      this.notify.error(
        "Atenção: Selecione sua área de atuação!"
      );
      return;
    }

    this.saving = true;
    abp.ui.setBusy();
    if (this.aceitouTermos) {
      this.createEmpresaDto.aceitouTermos = true;
    }
    this.createEmpresaDto.prestadoraServicoSuzano = this.prestadoraSuzano;
    this._empresaService
      .create(this.createEmpresaDto)
      .pipe(
        finalize(() => {
          this.saving = false;
          abp.ui.clearBusy();
        })
      )
      .subscribe(() => {
        this.notify.info(this.l("SavedSuccessfully"));
        this.authenticateModel.userNameOrEmailAddress =
          this.createEmpresaDto.email;
        this.authenticateModel.password = this.createEmpresaDto.senha;
        this.doLogin();
      });
  }

  getComoConheceu() {
    var comoConheceuEnum = [
      ComoConheceuEnum.BuscaNaInternet,
      ComoConheceuEnum.AnunciosEmRedesSociais,
      ComoConheceuEnum.AmigosFamiliares,
      ComoConheceuEnum.Outros,
    ];

    var generoTraduzido = comoConheceuEnum.map((valor) => {
      switch (valor) {
        case ComoConheceuEnum.BuscaNaInternet:
          return "Busca na internet";
        case ComoConheceuEnum.AnunciosEmRedesSociais:
          return "Anúncios em redes sociais";
        case ComoConheceuEnum.AmigosFamiliares:
          return "Amigos/Familiares";
        case ComoConheceuEnum.Outros:
          return "Outros";
        default:
          return "Desconhecido";
      }
    });

    return generoTraduzido;
  }

  onHowDidYouMeetSelected() {
    if (this.HowDidYouMeetSelected == ComoConheceuEnum.Outros) {
      this.showOtherInput = true;
    } else {
      this.showOtherInput = false;
    }

    this.createCandidatoDto.comoConheceu = this.HowDidYouMeetSelected;
    this.createEmpresaDto.comoConheceu = this.HowDidYouMeetSelected;
  }

  private doLogin() {
    this._appAuthService.authenticateModel = this.authenticateModel;
    this._appAuthService.authenticate(() => {
      if (this.openedByDialogCandidatarVaga) {
        this.candidatar();
      } else {
        window.location.href = window.location.origin;
        abp.ui.clearBusy();
      }
    });
  }

  candidatar() {
    let createCandidatoVaga: CreateCandidatoVagaDto =
      new CreateCandidatoVagaDto();
    if (
      this.candidato.curriculoDadoPessoal != null ||
      this.candidato.curriculoDadoPessoalAnexo != null
    ) {
      createCandidatoVaga.candidatoId = this.candidato.id;
      createCandidatoVaga.vagaId = this.vaga.id;
      this._candidatoVagaService.create(createCandidatoVaga).subscribe({
        complete: () => {
          abp.ui.clearBusy();
          this.bsModalRef.hide();
          abp.notify.success(
            this.l(
              `Parabéns! Você se candidatou com sucesso à vaga ${this.vaga.tituloVaga}. Fique atento às atualizações e confira o status da sua inscrição na seção de "Inscrições". Boa sorte!`
            )
          );
          window.location.href = window.location.origin;
        },
        error: (error) => {
          this.bsModalRef.hide();
          abp.ui.clearBusy();
        },
      });
    } else {
      this.bsModalRef.hide();
      this._candidatoService
        .tentativaCandidatarVaga(this.vaga.id)
        .pipe(
          finalize(() => {
            abp.ui.clearBusy();
          })
        )
        .subscribe((x) => {
          this.candidato = x;
          this.serviceCanditadoOptions.setCandidato(this.candidato);
          abp.notify.warn(
            this.l(
              "Antes de se candidatar à vaga, por favor, preencha seu currículo. Estamos ansiosos para saber mais sobre você!"
            )
          );
          this.router.navigate(["/candidato/meu-curriculo"]);
        });
    }
  }

  onChangeCnpj($event) {
    this.createEmpresaDto.cnpj = $event;

    if ($event.length == 14) {
      abp.ui.setBusy();

      this._buscarCnpjService.getEmpresa($event).subscribe((x) => {
        if (x) {
          if (x["RAZAO SOCIAL"])
            this.createEmpresaDto.razaoSocial = x["RAZAO SOCIAL"].toUpperCase();
          if (x["NOME FANTASIA"])
            this.createEmpresaDto.nome = x["NOME FANTASIA"].toUpperCase();
          if (x["DDD"] && x["TELEFONE"])
            this.createEmpresaDto.telefone = `${x["DDD"]}${x["TELEFONE"]}`;
          if (x["EMAIL"]) this.createEmpresaDto.email = x["EMAIL"];
        }
        abp.ui.clearBusy();
      });
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  onChangeQuantidadePessoaFamilia(quantidadePessoaFamilia: number) {
    if (quantidadePessoaFamilia === 0)
      this.exibirQuantidadePessoaFamiliaMaior5 = true;
    else this.exibirQuantidadePessoaFamiliaMaior5 = false;
  }

  handleUploadLaudoMedico(event) {
    const file = event.target.files[0];
    this.createLaudoMedicoDto.arquivoNome = file.name;
    this.createLaudoMedicoDto.arquivoTamanho = file.size;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.createLaudoMedicoDto.arquivoExtensao =
        file.name.split(".")[file.name.split(".").length - 1];
      this.createLaudoMedicoDto.arquivoBase64 = reader.result.toString();
    };
    this.cdr.markForCheck();
  }

  deleteFile() {
    this.createLaudoMedicoDto = new CreateLaudoMedicoDto();
    const fileInput = document.getElementById("file") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = null;
    }
    this.cdr.markForCheck();
  }

  verificarInputs() {
    if (this.createCandidatoDto.cpf == undefined) {
      this.notify.error("Atenção: CPF é obrigátorio!");
      return true;
    } else if (this.createCandidatoDto.nome == undefined || this.createCandidatoDto.nome == "") {
      this.notify.error("Atenção: Nome é obrigátorio!");
      return true;
    } else if (this.createCandidatoDto.sobrenome == undefined || this.createCandidatoDto.sobrenome == "") {
      this.notify.error("Atenção: Sobrenome é obrigátorio!");
      return true;
    } else if (this.createCandidatoDto.email == undefined) {
      this.notify.error("Atenção: E-mail é obrigátorio!");
      return true;
    } else if (!this.validateEmail()) {
      this.notify.error("Atenção: E-mail inválido!");
      return true;
    } else if (this.createCandidatoDto.telefone == undefined) {
      this.notify.error("Atenção: Telefone é obrigátorio!");
      return true;
    } else if (this.createCandidatoDto.telefone.length < 10) {
      this.notify.error("Atenção: número de telefone inválido");
      return true;
    } else if (this.createCandidatoDto.senha == undefined) {
      this.notify.error("Atenção: Senha é obrigátorio!");
      return true;
    } else if (this.createCandidatoDto.comoConheceu == undefined) {
      this.notify.error("Atenção: O campo como conheceu o conecta é obrigatório");
      return true;
    } else if (this.createCandidatoDto.comoConheceu != undefined && this.showOtherInput && this.createCandidatoDto.comoConheceuOutros == undefined) {
      this.notify.error("Atenção: Conte-nos como conheceu o Bússola Conecta");
      return true;
    }
    else if (this.createCandidatoDto.rendaBrutaFamiliarEnum == undefined) {
      this.notify.error("Atenção: renda famliar é obrigátorio!");
      return true;
    } else if (this.quantidadePessoaFamilia == undefined) {
      this.notify.error(
        "Atenção: quantidade de pessoas compõem o seu grupo familiar é obrigátorio!"
      );
      return true;
    } else if (
      this.exibirQuantidadePessoaFamiliaMaior5 &&
      this.quantidadePessoaFamiliaMaior5 == undefined
    ) {
      this.notify.error(
        "Atenção: quantidade de pessoas compõem o seu grupo familiar é obrigátorio!"
      );
      return true;
    } else if (!this.aceitouTermos) {
      this.notify.error(
        "Atenção: Aceite a Política de Privacidade e os Termos de Uso!"
      );
      return true;
    } else if (
      this.createCandidatoDto.pcd &&
      (this.createLaudoMedicoDto == undefined ||
        this.createLaudoMedicoDto.arquivoBase64 == undefined)
    ) {
      this.notify.error("Atenção: O laudo médico é obrigátorio!");
      return true;
    }
    return false;
  }

  validateEmail() {
    return !!this.createCandidatoDto.email.match(this.emailPattern);
  }

  setAreaAtuacao(){
    if(!this.prestadoraSuzano){
      this.createEmpresaDto.areaAtuacaoEmpresaEnum = undefined;
    }
  }
}
