<div>
  <div class="border-bottom p-3 bg-light rounded-top" style="background-color: #CDD6F1 !important;">
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
    <div class="text-center p-3">
      <div class="title-dialog">Recuperar Senha</div>
    </div>
  </div>
  <div class="modal-body rounded-bottom p-10">
    <p class="text-center pb-5">Vamos te enviar um e-mail com instruções para a criação de uma nova senha.</p>
    <div class="form-group">
      <label class="form-label" for="name" style="font-family: Poppins !important">
        E-mail
      </label>
      <div>
        <input type="email" class="form-control" id="emailAddress" name="emailAddress" required maxlength="256" email
          [(ngModel)]="model.emailAddress" #emailAddressModel="ngModel" #emailAddressEl />
        <abp-validation-summary [control]="emailAddressModel" [controlEl]="emailAddressEl"></abp-validation-summary>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col text-center">
        <button type="button" class="botao-enviar px-5 py-4 mt-8 mb-5" (click)="resetPassword()">
          Enviar
        </button>
      </div>
    </div>
  </div>
</div>










<!-- <form class="form-horizontal" autocomplete="off" #resetPasswordForm="ngForm" (ngSubmit)="resetPassword()">
    <abp-modal-header [title]="'ForgotMyPassword' | localize" (onCloseClick)="bsModalRef.hide()"></abp-modal-header>
    <div class="modal-body">
      <p class="text-center pb-10">Te enviaremos um e-mail para a criação de uma nova senha</p>
      <div class="form-group row required">
        <label class="col-md-3 col-form-label" for="emailAddress">
          {{ "EmailAddress" | localize }}
        </label>
        <div class="col-md-9">
          <input type="email" class="form-control" id="emailAddress" name="emailAddress" required maxlength="256" email
            [(ngModel)]="senha" #emailAddressModel="ngModel" #emailAddressEl />
          <abp-validation-summary [control]="emailAddressModel" [controlEl]="emailAddressEl"></abp-validation-summary>
        </div>
      </div>
    </div>
    <abp-modal-footer [cancelDisabled]="saving" [saveDisabled]="!resetPasswordForm.form.valid || saving"
      (onCancelClick)="bsModalRef.hide()" saveLabel="OK"></abp-modal-footer>
  </form> -->