<div>
    <div class="border-bottom p-3 bg-light rounded-top">
      <button
        type="button"
        class="btn-close close pull-right"
        aria-label="Close"
        (click)="bsModalRef.hide()"
      >
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
      <div class="text-center p-3">
        <div class="login-title">Entrar</div>
        <div class="login-subtitle">Ainda não está cadastrado?</div>
        <a href="javascript:void(0);" (click)="createAccount()" class="text-primary font-weight-bold">Cadastrar</a>
      </div>
    </div>
    <div class="modal-body rounded-bottom p-10">
      <form
        class="form-horizontal px-lg-30"
        autocomplete="off"
        #loginForm="ngForm"
        novalidate
        (ngSubmit)="login()"
      >
        <div class="form-group">
          <label class="input-label" for="inputEmail">E-mail</label>
          <input
            type="email"
            class="form-control"
            id="inputEmail"
            placeholder="Escreva seu email"
            [(ngModel)]="authService.authenticateModel.userNameOrEmailAddress"
            name="email"
            #userNameOrEmailAddressModel="ngModel"
            required
            maxlength="256"
            #userNameOrEmailAddressEl
          />
          <abp-validation-summary
            [control]="userNameOrEmailAddressModel"
            [controlEl]="userNameOrEmailAddressEl"
          >
          </abp-validation-summary>
        </div>
        <div class="form-group">
          <label class="input-label" for="inputPassword">Senha</label>
          <input
            type="password"
            class="form-control"
            id="inputPassword"
            placeholder="Escreva sua senha"
            [(ngModel)]="authService.authenticateModel.password"
            name="password"
            required
            maxlength="32"
            #passwordModel="ngModel"
            #passwordEl
          />
          <abp-validation-summary
            [control]="passwordModel"
            [controlEl]="passwordEl"
          ></abp-validation-summary>
        </div>
        <div class="form-check pl-2">
          <input
            type="checkbox"
            class="check-input"
            id="rememberMe"
            name="rememberMe"
            [(ngModel)]="authService.rememberMe"
          />
          <label for="rememberMe" class="check-label"> Manter conectado </label>
        </div>
  
        <div class="text-center mt-10">
          <button
            type="submit"
            class="btn btn-primary default-button nav-button px-6 py-3 mat-elevation-z3 w-75 mb-10"
            [disabled]="!loginForm.form.valid || submitting"
          >
            Entrar
          </button>
          <div>
            <a
              href="javascript:void(0);"
              (click)="forgotPassword()"
              class="text-primary forgot"
              >Esqueci ou não tenho a senha</a
            >
          </div>
        </div>
      </form>
    </div>
  </div>
  