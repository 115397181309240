import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class JsonDataService {
  private cidadeSelecionadaSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  cidadeSelecionada$: Observable<string> = this.cidadeSelecionadaSubject.asObservable();

  private cidades: any[];
  private estados: any[];

  constructor(private http: HttpClient) {}


  getEstados(): Observable<any[]> {
    return this.http.get<any[]>('../../assets/json/estados.json').pipe(
      map(estados => {
        this.estados = estados;
        return estados;
      })
    );
  }

  getCidades(): Observable<any[]> {
    return this.http.get<any[]>('../../assets/json/cidades.json').pipe(
      map(cidades => {
        this.cidades = cidades;
        return cidades;
      })
    );
  }

  getCidadeNome(cidadeId: string): string {
    const cidade = this.cidades.find(c => c.ID === cidadeId);
    return cidade ? cidade.Nome : '';
  }

  getEstadoSigla(estadoId: string): string {
    const estado = this.estados.find(e => e.ID === estadoId);
    return estado ? estado.Sigla : '';
  }

  getEstadoId(estadoSigla: string): string {
    const estado = this.estados.find(e => e.Sigla === estadoSigla);
    return estado ? estado.ID : '';
  }

  selecionarCidade(cidadeId: string): void {
    this.cidadeSelecionadaSubject.next(cidadeId);
  }

  getCidadeId(nomeCidade: string): string {
    const cidade = this.cidades.find(e => e.Nome === nomeCidade);
    return cidade ? cidade.ID : '';
  }
}
