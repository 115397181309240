import {
  Component,
  EventEmitter,
  Injector,
  OnInit,
  Output,
} from "@angular/core";
import { AppComponentBase } from "@shared/app-component-base";
import { AppAuthService } from "@shared/auth/app-auth.service";
import {
  RoleDto,
} from "@shared/service-proxies/service-proxies";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SessionServiceProxy } from './../../shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Component({
selector: "app-role-selection-dialog",
templateUrl: "./role-selection-dialog.component.html",
styleUrls: ["./role-selection-dialog.component.css"],
})
export class RoleSelectionDialogComponent
extends AppComponentBase
implements OnInit
{
public roles: Array<RoleDto> = [];

@Output() roleSelected = new EventEmitter<string>();

constructor(
  public injector: Injector,
  public bsModalRef: BsModalRef,
  public authService: AppAuthService,
  private _sessionService: SessionServiceProxy,
) {
  super(injector);
}

ngOnInit(): void {
  abp.ui.setBusy();
  this._sessionService.getMyRoles().pipe(
    finalize(() => {
      abp.ui.clearBusy();
    })
  ).subscribe((result) => {
    if (result.items)
      this.roles = result.items;
  });
}

onSelectRole(role: string) {
  this.roleSelected.emit(role);
  this.bsModalRef.hide();
}
}
