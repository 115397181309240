import { Component, Injector } from "@angular/core";
import { finalize } from "rxjs/operators";
import { appModuleAnimation } from "@shared/animations/routerTransition";
import { AppComponentBase } from "@shared/app-component-base";
import {
  CandidatoDto,
  CandidatoServiceProxy,
  CreateCandidatoVagaDto,
  VagaDto,
  VagaServiceProxy,
} from "@shared/service-proxies/service-proxies";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ActivatedRoute, Router } from "@angular/router";
import { CandidatoService } from "candidato/service/candidato.service";
import { PixelService } from "ngx-multi-pixel";
import { TokenService } from 'abp-ng2-module';
import { ConfirmationDialogComponent } from "./candidatar/candidatar-vaga.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  templateUrl: "./detalhes-vaga.component.html",
  styleUrls: ["./detalhes-vaga.component.scss"],
  animations: [appModuleAnimation()],
})
export class DetalhesVagaComponent extends AppComponentBase {
  vaga: VagaDto = new VagaDto();
  candidato: CandidatoDto = new CandidatoDto();
  createCandidatoVaga: CreateCandidatoVagaDto = new CreateCandidatoVagaDto();
  isInscrito: boolean;
  telefoneFormatado: string;

  constructor(
    injector: Injector,
    private _route: ActivatedRoute,
    private _vagaService: VagaServiceProxy,
    private _candidatoService: CandidatoServiceProxy,
    private serviceCanditadoOptions: CandidatoService,
    public bsModalRef: BsModalRef,
    private pixel: PixelService,
    private _tokenService: TokenService,
    private dialog: MatDialog,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.onConsent();
    if (this.isLoggedIn()){
      this.isInscrito = this.serviceCanditadoOptions.getInscrito();
      this.candidato = this.serviceCanditadoOptions.getCandidato();
    }

    this._route.params.subscribe(params => {
      abp.ui.setBusy();
      this._vagaService.get(params['id'])
        .pipe(
          finalize(() => {
            abp.ui.clearBusy();
          })
        )
        .subscribe(x => {
          this.vaga = x;
        });
    });
  }

  onConsent(): void {
    this.pixel.initialize();
    this.pixel.track("ViewContent", {
      content_name: "Detalhes vaga - Candidato"
    });
  }

  candidatar() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        vaga: this.vaga,
        candidato: this.candidato,
      },
    });
  }

  getCandidato() {
    abp.ui.setBusy();
    this._candidatoService.getAbp()
      .pipe(
        finalize(() => {
          abp.ui.clearBusy();
        })
      ).subscribe(x => {
        this.candidato = x;
        this.serviceCanditadoOptions.setCandidato(this.candidato);
        this.formatarTelefone(this.candidato.telefone);
      });
  }

  formatarTelefone(telefone: string) {
    const telefoneLimpo = telefone.replace(/\D/g, '');
    this.telefoneFormatado = `(${telefoneLimpo.slice(0, 2)}) ${telefoneLimpo.slice(2, 3)} ${telefoneLimpo.slice(3, 7)}-${telefoneLimpo.slice(7)}`;
  }

  isLoggedIn(): boolean {
    const token = this._tokenService.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  private isTokenExpired(token: string): boolean {
    const tokenPayload = this.decodeToken(token);

    if (tokenPayload && tokenPayload.exp) {
      const expirationDateSeconds = tokenPayload.exp;

      const expirationDateMilliseconds = expirationDateSeconds * 1000;

      const currentTimestampMilliseconds = new Date().getTime();

      return expirationDateMilliseconds < currentTimestampMilliseconds;
    }

    return true;
  }

  private decodeToken(token: string): any {
    try {
      const tokenPayloadBase64 = token.split('.')[1];
      const tokenPayloadJson = atob(tokenPayloadBase64);
      return JSON.parse(tokenPayloadJson);
    } catch (error) {
      return null;
    }
  }
}
