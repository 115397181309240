<div class="row mt-6 mb-6 card-ordenacao">
  <div class="col">
    <nav class="navbar navbar-expand mb-3">
      <span class="navbar-brand subtitle">Ordenação</span>

      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" href="#" (click)="selectOption($event, 'mais_recente')"
            [ngClass]="{ 'font-weight-bold text-primary': ordenacao === 'mais_recente' }">Mais Recentes</a>
        </li>
      </ul>

      <form class="form-inline">
        <div class="input-group">
          <input class="form-control" type="search" placeholder="Pesquisar" [(ngModel)]="textobusca" name="textobusca"
            (input)="updateSearchText($event.target.value)">
          <div class="input-group-append">
            <span class="input-group-text">
              <i class="fas fa-search"></i>
            </span>
          </div>
        </div>
      </form>
    </nav>

    <mat-divider class="mb-5"></mat-divider>

  </div>
</div>