<div>
  <div class="border-bottom p-3 bg-light rounded-top" style="background-color: #CDD6F1 !important;">
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
    <div class="text-center p-3">
      <div class="title-dialog">Cadastrar</div>
    </div>
  </div>
  <div class="modal-body rounded-bottom p-10">
    <div [ngSwitch]="screen">
      <div *ngSwitchCase="ScreenNames.Selection">
        <ng-container *ngTemplateOutlet="roleSelection"></ng-container>
      </div>
      <div *ngSwitchCase="ScreenNames.Candidate" [@routerTransition]>
        <ng-container *ngTemplateOutlet="candidateForm"></ng-container>
      </div>
      <div *ngSwitchCase="ScreenNames.Company" [@routerTransition]>
        <ng-container *ngTemplateOutlet="companyForm"></ng-container>
      </div>
      <div *ngSwitchCase="ScreenNames.VerifyToken" [@routerTransition]>
        <ng-container *ngTemplateOutlet="verifyTokenScreen"></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #roleSelection>
  <div class="row">
    <div class="col text-center">
      <object type="image/svg+xml" data="assets/media/svg/illustrations/Sou_um_candidato.svg"
        class="illustration"></object>
      <div class="caixa-texto mt-5">
        <p>
          Sou um candidato e estou interessado em ofertas de emprego
        </p>
      </div>

      <button type="button" class="botao-escolha px-6 py-3 mt-5 mb-10"
        (click)="onOptionSelected(ScreenNames.Candidate)">
        Sou um candidato
      </button>
    </div>
    <div class="col text-center">
      <object type="image/svg+xml" data="assets/media/svg/illustrations/Sou_uma_empresa.svg"
        class="illustration"></object>
      <div class="caixa-texto mt-5">
        <p>Ofereço vagas para futuros colaboradores</p>
      </div>
      <button type="button" class="botao-escolha px-6 py-3 mt-5 mb-10" (click)="onOptionSelected(ScreenNames.Company)">
        Sou uma empresa
      </button>
    </div>
  </div>
</ng-template>

<ng-template #candidateForm>
  <div class="row align-items-center">
    <div class="col-xs-12 col-lg-4 d-none d-lg-block">
      <object type="image/svg+xml" data="assets/media/svg/illustrations/cadastrar.svg" class="w-100"></object>
    </div>
    <div class="col-xs-12 col-lg-8">
      <form class="form-horizontal" autocomplete="off" #createUserModal="ngForm" (ngSubmit)="createCandidato()">

        <div class="form-group">
          <label class="form-label required" for="name" style="font-family: Poppins !important">
            Nome
          </label>
          <div>
            <input required type="text" class="form-control" name="name" id="name" maxlength="128"
              [(ngModel)]="createCandidatoDto.nome" #nameModel="ngModel" #nameEl />
            <abp-validation-summary [control]="nameModel" [controlEl]="nameEl"></abp-validation-summary>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label required" for="sobrenome">
            Sobrenome
          </label>
          <div>
            <input required type="text" class="form-control" name="sobrenome" id="sobrenome"
              [(ngModel)]="createCandidatoDto.sobrenome" #sobrenomeModel="ngModel" #sobrenomeEl />
            <abp-validation-summary [control]="sobrenomeModel" [controlEl]="sobrenomeEl"></abp-validation-summary>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label required" for="name">
            CPF
          </label>
          <div>
            <input required type="text" class="form-control" name="cpf" id="cpf" mask="000.000.000-00"
              [(ngModel)]="createCandidatoDto.cpf" #cpfModel="ngModel" #cpfEl />
            <abp-validation-summary [control]="cpfModel" [controlEl]="cpfEl"></abp-validation-summary>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label required" for="emailAddress" style="font-family: Poppins !important">
            Endereço de e-mail
          </label>
          <input required type="email" class="form-control" name="emailAddress" id="emailAddress" maxlength="256"
            [(ngModel)]="createCandidatoDto.email" email #emailAddressModel="ngModel" #emailAddressEl
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
          <abp-validation-summary [control]="emailAddressModel" [controlEl]="emailAddressEl"></abp-validation-summary>
        </div>

        <div class="form-group">
          <label class="form-label required" for="phoneNumber" style="font-family: Poppins !important">
            Telefone
          </label>
          <div>
            <input required type="tel" class="form-control" name="phoneNumber" id="phoneNumber"
              mask="(00) 0000-0000||(00) 0 0000-0000" maxlength="256" [(ngModel)]="createCandidatoDto.telefone"
              #phoneNumberModel="ngModel" #phoneNumberEl (blur)="telefoneOnBlurCandidato()" />
            <abp-validation-summary [control]="phoneNumberModel" [controlEl]="phoneNumberEl"></abp-validation-summary>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label required" for="HowDidYouMeet"> Como ficou
            sabendo do Bússola Conecta? </label>
          <div class="select-wrapper">
            <select name="HowDidYouMeet" required class="form-control" id="HowDidYouMeet"
              [(ngModel)]="HowDidYouMeetSelected" (change)="onHowDidYouMeetSelected()" #HowDidYouMeetModel="ngModel"
              #HowDidYouMeetEl style="height: 100%;">
              <option *ngFor="let HowDidYouMeet of HowDidYouMeetList" [value]="HowDidYouMeet">
                {{ HowDidYouMeet }}
              </option>
            </select>
            <div class="select-icon">
              <i class="fas fa-chevron-down"></i>
            </div>
          </div>

          <div class="form-group" *ngIf="showOtherInput">
            <input *ngIf="showOtherInput" required type="text" class="form-control mt-5" name="HowDidYouMeetOther"
              id="HowDidYouMeetOther" maxlength="256" [(ngModel)]="createCandidatoDto.comoConheceuOutros"
              #HowDidYouMeetOtherModel="ngModel" #HowDidYouMeetOther
              placeholder="Conte-nos como conheceu o Bússola Conecta" />

            <abp-validation-summary [control]="HowDidYouMeetModel"
              [controlEl]="HowDidYouMeetEl"></abp-validation-summary>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label required" style="font-family: Poppins !important">
            Quantas pessoas compõem o seu grupo familiar?
          </label>
          <div>
            <mat-radio-group required name="quantidadePessoaFamilia"
              (change)="onChangeQuantidadePessoaFamilia(quantidadePessoaFamilia)" [(ngModel)]="quantidadePessoaFamilia"
              #quantidadePessoaFamiliaModel="ngModel" #quantidadePessoaFamiliaEl class="d-flex flex-column">
              <mat-radio-button name="quantidadePessoaFamilia" class="checkbox-custom" [value]="1">1
                pessoa</mat-radio-button>
              <mat-radio-button name="quantidadePessoaFamilia" class="checkbox-custom" [value]="2">2
                pessoas</mat-radio-button>
              <mat-radio-button name="quantidadePessoaFamilia" class="checkbox-custom" [value]="3">3
                pessoas</mat-radio-button>
              <mat-radio-button name="quantidadePessoaFamilia" class="checkbox-custom" [value]="0">4 ou mais
                pessoas</mat-radio-button>
            </mat-radio-group>
            <abp-validation-summary [control]="quantidadePessoaFamiliaModel"
              [controlEl]="quantidadePessoaFamiliaEl"></abp-validation-summary>
          </div>
        </div>

        <div class="form-group" *ngIf="exibirQuantidadePessoaFamiliaMaior5">
          <label class="form-label required" style="font-family: Poppins !important">
            Especifique
          </label>
          <div>
            <input required class="form-control" [required]="exibirQuantidadePessoaFamiliaMaior5" type="number" min="4"
              max="50" [(ngModel)]="quantidadePessoaFamiliaMaior5" #quantidadePessoaFamiliaMaior5Model="ngModel"
              #quantidadePessoaFamiliaMaior5El>
            <abp-validation-summary [control]="quantidadePessoaFamiliaMaior5Model"
              [controlEl]="quantidadePessoaFamiliaMaior5El"></abp-validation-summary>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label required" style="font-family: Poppins !important">
            Renda bruta familiar
          </label>
          <div>
            <mat-radio-group required name="rendaBrutaFamiliar" [(ngModel)]="createCandidatoDto.rendaBrutaFamiliarEnum"
              #rendaBrutaFamiliarModel="ngModel" #rendaBrutaFamiliarEl class="d-flex flex-column"
              (blur)="RendaBrutaOnBlurCandidato()">
              <mat-radio-button *ngFor="let renda of rendasBrutasFamiliaresEnum" name="rendaBrutaFamiliar"
                class="checkbox-custom" [value]="renda.chave">{{renda.valor}}</mat-radio-button>
            </mat-radio-group>
            <abp-validation-summary [control]="rendaBrutaFamiliarModel"
              [controlEl]="rendaBrutaFamiliarEl"></abp-validation-summary>
          </div>
        </div>

        <div class="form-group">
          <mat-checkbox [(ngModel)]="createCandidatoDto.beneficiarioSuzano" class="checkbox-custom"
            [title]="beneficiarioSuzano">
            <label class="col-form-label ml-2" style="text-wrap: pretty;">
              Já participou ou está participando de algum curso, capacitação ou treinamento oferecido ou patrocinado pela Suzano?
            </label>
          </mat-checkbox>

          <small style="display: flex;">
            {{ beneficiarioSuzano }}
          </small>

        </div>

        <div class="row align-items-start">
          <div class="col-md-2 form-group d-flex align-items-center">
            <mat-checkbox [(ngModel)]="createCandidatoDto.pcd" class="checkbox-custom" [title]="pcdTitle">
              <label class="col-form-label ml-2">
                Sou PcD
                <span class="icon-container ml-2 align-self-center" [title]="pcdTitle"><i
                    class="fa fa-question-circle"></i></span>
              </label>
            </mat-checkbox>
          </div>
        </div>

        <p *ngIf="createCandidatoDto.pcd" class="subtitulo mb-5 mt-2 ">Por favor, clique abaixo para anexar o laudo
          médico.</p>
        <div *ngIf="createCandidatoDto.pcd" class="row justify-content-between mb-5">
          <div class="parent mt-2 mb-2" style="display: flex; width: 100%; align-items: center;flex: 1 0 100%;">
            <input [disabled]="createLaudoMedicoDto.arquivoUrl" type="file" [accept]="'.pdf'" style="display: none;"
              class="custom-file-input" id="file" (change)="handleUploadLaudoMedico($event)" required>
            <label class="custom-file-label" for="file" style="display: none;">{{ "Adicionar" | localize
              }}</label>
            <label class="btn botao-laudo btn-sm mr-0" for="file" style="font-weight: normal;">
              {{ "Adicionar Laudo Médico" | localize }}
            </label>
          </div>
        </div>

        <div class="d-flex flex-row justify-content-between align-items-center mb-5 p-2 rounded lista-arquivos"
          *ngIf="createLaudoMedicoDto && createLaudoMedicoDto.arquivoTamanho > 0 && createCandidatoDto.pcd">
          <div class="col-6">{{ createLaudoMedicoDto.arquivoNome }}</div>
          <button [title]="'Delete' | localize" type="button" class="btn btn-icon btn-danger btn-sm mx-3"
            (click)="deleteFile()">
            <i class="fas fa-trash"></i>
          </button>
        </div>

        <div class="form-group">
          <label class="form-label required" for="password" style="font-family: Poppins !important">
            Senha
          </label>

          <div class="input-group">
            <input type="{{ showPassword ? 'text' : 'password' }}" class="form-control" name="password" id="password"
              required maxlength="32" validateEqual="confirmPassword" reverse="true"
              pattern="^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-z])(?!.*\s).{8,}$" [(ngModel)]="createCandidatoDto.senha"
              #passwordModel="ngModel" #passwordEl />

            <div class="input-group-append">
              <span class="input-group-text" (click)="togglePasswordVisibility()">
                <i class="fa" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
              </span>
            </div>
          </div>

          <abp-validation-summary [control]="passwordModel" [controlEl]="passwordEl"
            [customValidationErrors]="passwordValidationErrors"></abp-validation-summary>
        </div>

        <div class="form-group d-flex mb-0">
          <section>
            <mat-checkbox color="success" [(ngModel)]="aceitouTermos" class="checkbox-custom">
              <label class="col-form-label ml-2 required" for="acceptedTermsAndPrivacyPolicy">
                Li e aceito a
                <a routerLink="/politica-de-privacidade" target="_blank" class="text-primary font-weight-bold">Política
                  de
                  Privacidade</a>
                e os
                <a routerLink="/termos-de-uso" target="_blank" class="text-primary font-weight-bold">Termos de Uso</a>
              </label>
            </mat-checkbox>
          </section>
        </div>


        <div class="text-center">
          <button type="submit" class="botao-cadastre px-6 py-3 mt-10" [disabled]="saving">
            Cadastre-se
          </button>
        </div>

      </form>
    </div>
  </div>
</ng-template>

<ng-template #companyForm>
  <div class="row align-items-center">
    <div class="col-xs-12 col-lg-4 d-none d-lg-block">
      <object type="image/svg+xml" data="assets/media/svg/illustrations/cadastrar.svg" class="w-100"></object>
    </div>
    <div class="col-xs-12 col-lg-8">
      <form class="form-horizontal" autocomplete="off" #createUserModal="ngForm" (ngSubmit)="createEmpresa()">

        <div class="form-group">
          <label class="form-label required " for="cnpj" style="font-family: Poppins !important"> CNPJ </label>
          <div>
            <input type="text" class="form-control" name="cnpj" id="cnpj" required mask="00.000.000/0000-00"
              maxlength="18" [(ngModel)]="createEmpresaDto.cnpj" (ngModelChange)="onChangeCnpj($event)"
              #cnpjReasonModel="ngModel" #cnpjReasonEl />
            <abp-validation-summary [control]="cnpjReasonModel" [controlEl]="cnpjReasonEl"></abp-validation-summary>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label required" for="corporateReason" style="font-family: Poppins !important"> Razão Social
          </label>
          <div>
            <input required type="text" class="form-control" name="corporateReason" id="corporateReason" required
              maxlength="256" [(ngModel)]="createEmpresaDto.razaoSocial" #corporateReasonModel="ngModel"
              #corporateReasonEl />
            <abp-validation-summary [control]="corporateReasonModel"
              [controlEl]="corporateReasonEl"></abp-validation-summary>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label required" for="name" style="font-family: Poppins !important"> Nome Fantasia </label>
          <div>
            <input required type="text" class="form-control" name="fantasyName" id="fantasyName" maxlength="256"
              [(ngModel)]="createEmpresaDto.nome" #fantasyNameModel="ngModel" #fantasyNameEl />
            <abp-validation-summary [control]="fantasyNameModel" [controlEl]="fantasyNameEl"></abp-validation-summary>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label required" for="HowDidYouMeet" style="font-family: Poppins !important"> Como ficou
            sabendo do Bússola Conecta? </label>
          <div class="select-wrapper">
            <select name="HowDidYouMeet" required class="form-control" id="HowDidYouMeet"
              [(ngModel)]="HowDidYouMeetSelected" (change)="onHowDidYouMeetSelected()" #HowDidYouMeetModel="ngModel"
              #HowDidYouMeetEl style="height: 100%;">
              <option *ngFor="let HowDidYouMeet of HowDidYouMeetList" [value]="HowDidYouMeet">
                {{ HowDidYouMeet }}
              </option>
            </select>
            <div class="select-icon">
              <i class="fas fa-chevron-down"></i>
            </div>
          </div>

          <input *ngIf="showOtherInput" required type="text" class="form-control mt-5" name="HowDidYouMeetOther"
            id="HowDidYouMeetOther" maxlength="256" [(ngModel)]="createEmpresaDto.comoConheceuOutros"
            #HowDidYouMeetOtherModel="ngModel" #HowDidYouMeetOther
            placeholder="Conte-nos como conheceu o Bússola Conecta" />

          <abp-validation-summary [control]="HowDidYouMeetModel" [controlEl]="HowDidYouMeetEl"></abp-validation-summary>
        </div>


        <div class="form-group">
          <label class="form-label required" for="emailAddress" style="font-family: Poppins !important">
            Endereço de e-mail
          </label>
          <div>
            <input type="email" class="form-control" name="emailAddress" id="emailAddress" required maxlength="256"
              [(ngModel)]="createEmpresaDto.email" email #emailAddressModel="ngModel" #emailAddressEl
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" />
            <abp-validation-summary [control]="emailAddressModel" [controlEl]="emailAddressEl"></abp-validation-summary>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label required" for="phoneNumber" style="font-family: Poppins !important">
            Telefone
          </label>
          <div>
            <input required type="tel" class="form-control" name="phoneNumber" id="phoneNumber"
              mask="(00) 0000-0000||(00) 0 0000-0000" maxlength="256" [(ngModel)]="createEmpresaDto.telefone"
              #phoneNumberModel="ngModel" #phoneNumberEl />
            <abp-validation-summary [control]="phoneNumberModel" [controlEl]="phoneNumberEl"></abp-validation-summary>
          </div>
        </div>

        <div class="form-group">
          <label class="form-label required" for="password" style="font-family: Poppins !important">
            Senha
          </label>

          <div class="input-group">
            <input type="{{ showPassword ? 'text' : 'password' }}" class="form-control" name="password" id="password"
              required maxlength="32" validateEqual="confirmPassword" reverse="true"
              pattern="^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[a-z])(?!.*\s).{8,}$" [(ngModel)]="createEmpresaDto.senha"
              #passwordModel="ngModel" #passwordEl />
            <div class="input-group-append">
              <span class="input-group-text" (click)="togglePasswordVisibility()">
                <i class="fa" [ngClass]="showPassword ? 'fa-eye-slash' : 'fa-eye'"></i>
              </span>
            </div>
          </div>

          <abp-validation-summary [control]="passwordModel" [controlEl]="passwordEl"
            [customValidationErrors]="passwordValidationErrors"></abp-validation-summary>
        </div>

        <div class="form-group d-flex mb-0">
          <section>
            <mat-checkbox color="success" [(ngModel)]="prestadoraSuzano" class="checkbox-custom"
              name="prestadoraServicoSuzano" (change)="setAreaAtuacao()">
              <label class="col-form-label ml-2" for="prestadoraServicoSuzano">
                Prestadora de serviço da Suzano
              </label>
            </mat-checkbox>
          </section>
        </div>

        <div class="form-group mb-0" *ngIf="prestadoraSuzano">
          <label class="form-label required" style="font-family: Poppins !important">
            Qual área?
          </label>
          <div>
            <mat-radio-group required name="areaAtuacaoEmpresa" [(ngModel)]="createEmpresaDto.areaAtuacaoEmpresaEnum"
              #areaAtuacaoEmpresaModel="ngModel" #areaAtuacaoEmpresaEl class="d-flex flex-column">
              <mat-radio-button name="areaAtuacaoEmpresa" class="checkbox-custom"
                [value]="0">Florestal</mat-radio-button>
              <mat-radio-button name="areaAtuacaoEmpresa" class="checkbox-custom"
                [value]="1">Industrial</mat-radio-button>
              <mat-radio-button name="areaAtuacaoEmpresa" class="checkbox-custom" [value]="2">Florestal e
                Industrial</mat-radio-button>
            </mat-radio-group>
            <abp-validation-summary [control]="areaAtuacaoEmpresaModel"
              [controlEl]="areaAtuacaoEmpresaEl"></abp-validation-summary>
          </div>
        </div>

        <div class="form-group d-flex mb-0">
          <section>
            <mat-checkbox color="success" [(ngModel)]="aceitouTermos" class="checkbox-custom">
              <label class="col-form-label ml-2 required" for="acceptedTermsAndPrivacyPolicy">
                Li e aceito a
                <a routerLink="/politica-de-privacidade" target="_blank" class="text-primary font-weight-bold">Política
                  de
                  Privacidade</a>
                e os
                <a routerLink="/termos-de-uso" target="_blank" class="text-primary font-weight-bold">Termos de Uso</a>
              </label>
            </mat-checkbox>
          </section>
        </div>

        <div class="text-center">
          <button type="submit" class="botao-cadastre px-6 py-3 mt-10"
            [disabled]="!createUserModal.form.valid || saving">
            Cadastre-se
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #verifyTokenScreen>
  <code-input-countdown #codeInput text="Enviamos um código de verificação para o telefone que você informou. Por
    favor digite o código nos campos abaixo:" (codeCompleted)="onCodeCompleted($event)"
    (resendCode)="requestToken()"></code-input-countdown>
</ng-template>
