<section class="content-header">
  <div class="row mb-5 d-flex align-items-center">
    <div class="col-md-4">
      <h1 class="title-filtro">Filtros</h1>
    </div>
  </div>

  <p class="subtitle">Empresa</p>
  <div class="form-group mb-0 col col-xs-6">
    <label class="col-form-label subtitle-2">Filtrar por empresa</label>
    <input type="text" class="form-control col-md-12" name="empresa" id="empresa" maxlength="256"
      [(ngModel)]="nomeEmpresa" #empresaModel="ngModel" #empresaEl placeholder="Indique o nome ou razão social"
      (input)="OnNgModelChangeEmpresa($event.target.value)" />
  </div>



  <p class="subtitle mt-10">Filtrar vagas para PcD?
    <span class="icon-container ml-2 align-self-center" [title]="pcdTitle">
      <i class="fa fa-question-circle custom-icon"></i>
    </span>
  </p>

  <div class="form-group mb-0 col col-xs-6">
    <div>
      <mat-slide-toggle [color]="'primary'" [(ngModel)]="pcd" (change)="onSlideTogglePcdChange($event)">
        {{ pcd ? 'Sim' : 'Não' }}
      </mat-slide-toggle>
    </div>
  </div>

  <p class="subtitle mt-10">Localização</p>
  <div class="form-group mb-0 col col-xs-6">
    <label class="col-form-label subtitle-2">Estado</label>
    <ng-multiselect-dropdown [placeholder]="'Selecione um Estado'" [settings]="dropdownSettings" [data]="estados"
      [(ngModel)]="selectedEstado" (onSelect)="onEstadoSelected($event)" (onDeSelect)="onEstadoDeSelected($event)">
    </ng-multiselect-dropdown>
  </div>
  <div class="form-group mb-0 col col-xs-6">
    <label class="col-form-label subtitle-2">Cidade</label>
    <ng-multiselect-dropdown [placeholder]="'Selecione uma cidade'" [settings]="dropdownSettings" [data]="cidades"
      [disabled]="desabilitarCidade" [(ngModel)]="selectedCidade" (onSelect)="onCidadeSelected($event)"
      (onDeSelect)="onCidadeDeSelected($event)">
    </ng-multiselect-dropdown>
  </div>
  <p class="subtitle mt-5 mb-0">Cargo</p>
  <div class="form-group mb-0 mt-0 col col-xs-6">
    <label class="col-form-label subtitle-2">Nome</label>
    <ng-multiselect-dropdown [placeholder]="'Escolha um cargo'" [settings]="dropdownSettingsCargo" [data]="cargos"
      [(ngModel)]="selectedCargos" (onSelect)="onCargoSelected($event)" (onDeSelect)="onCargoDeSelected($event)"
      (onSelectAll)="onCargoSelectedAll()" (onDeSelectAll)="onCargoDeSelectedAll()">
    </ng-multiselect-dropdown>
  </div>

  <p class="subtitle mt-5">Salário</p>
  <div class="form-group mb-0 col col-12">
    <div class="d-flex align-items-center">
      <div class="">
        <label class="col-form-label subtitle-2 pr-3">A partir de</label>
      </div>
    </div>
  </div>
  <div class="input-group">
    <input type="text" class="form-control col-12" name="valorSalario" id="valorSalario" maxlength="256"
      [(ngModel)]="salario" #valorSalarioModel="ngModel" #valorSalarioEl placeholder="0,00" currencyMask
      [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" />
    <div class="input-group-append">
      <span class="input-group-text" (click)="atualizarSalario()">
        <i class="fa fa-search"></i>
      </span>
    </div>
  </div>
  <mat-slider class="example-margin custom-slider w-100" [disabled]="disabled" [invert]="invert" [max]="max" [min]="min"
    [step]="step" [thumbLabel]="thumbLabel" [tickInterval]="getSliderTickInterval()" [(ngModel)]="salario"
    [color]="'primary'" [vertical]="vertical" aria-labelledby="example-name-label" (change)="sliderSalarioChanged()"
    [displayWith]="formatLabel">
  </mat-slider>

  <p class="subtitle mt-5">Modelo de Trabalho</p>

  <div class="form-group square-radio">
    <mat-radio-group [(ngModel)]="modeloTrabalho" (change)="selecaoModeloChange()">

      <mat-radio-button value="todas">Todos</mat-radio-button>

      <mat-radio-button *ngFor="let modelo of listaModeloTrabalho" [value]="modelo.value" style="display: block;">
        {{ modelo.name }}
      </mat-radio-button>
    </mat-radio-group>
  </div>
</section>