import { Component, Injector, Input } from "@angular/core";
import { AppComponentBase } from "@shared/app-component-base";
import localePtBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
import { CandidatoDto, VagaDto } from "@shared/service-proxies/service-proxies";
import { Router } from "@angular/router";
import { CandidatoService } from "candidato/service/candidato.service";
import { ConfirmationDialogComponent } from "./detalhes/candidatar/candidatar-vaga.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "card-candidato-vaga",
  templateUrl: "./card-candidato-vaga.component.html",
  styleUrls: ["./card-candidato-vaga.component.scss"],
})
export class CardCandidatoVagaComponent extends AppComponentBase {
  @Input() vaga: VagaDto;
  @Input() isInscrito: boolean;
  pcdTitle: string = this.l("Vaga para pessoas com deficiência.");
  candidato: CandidatoDto = new CandidatoDto();

  constructor(
    injector: Injector,
    private _router: Router,
    private candidatoService: CandidatoService,
    private dialog: MatDialog,
  ) {
    super(injector);
    registerLocaleData(localePtBr);
  }

  detalhes(){
    this.candidatoService.setInscrito(this.isInscrito);
    this._router.navigate(['candidato', 'detalhes-vaga', this.vaga.id]);
  }

  truncateText(text: string, limit: number): string {
    return text.length > limit ? text.substring(0, limit) + '...' : text;
  }

  candidatar() {
    this.candidato = this.candidatoService.getCandidato();
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        vaga: this.vaga,
        candidato: this.candidato,
      },
    });
  }

}
