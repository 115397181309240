<div class="modal-body rounded-bottom p-10 text-center mat-elevation-z3">
    <p class="p-5 mb-5">
        Parece que você está cadastrado em mais de uma função. Selecione a função
        com a qual deseja realizar o login:
    </p>
    <button *ngFor="let role of roles" type="submit"
        class="btn btn-primary default-button nav-button px-6 py-3 mat-elevation-z3 mb-5 d-block w-100"
        (click)="onSelectRole(role.name)">
        {{ role.displayName }}
    </button>
</div>