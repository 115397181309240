import { Component, Inject, Injector, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AppComponentBase } from "@shared/app-component-base";
import {
  CandidatoDto,
  CandidatoServiceProxy,
  CandidatoVagaServiceProxy,
  CreateCandidatoVagaDto,
  VagaDto,
} from "@shared/service-proxies/service-proxies";
import { TokenService } from "abp-ng2-module";
import { CandidatoService } from "candidato/service/candidato.service";
import { CreateAccountDialogComponent } from "initial/create-account-dialog/create-account-dialog.component";
import { LoginDialogComponent } from "initial/login-dialog/login-dialog.component";
import { LoginOrCreateSelectionDialogComponent } from "initial/login-or-create-dialog/login-or-create-selection-dialog.component";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { finalize } from "rxjs/operators";

@Component({
  selector: "app-custom-dialog",
  templateUrl: "./candidatar-vaga.component.html",
  styleUrls: ["./candidatar-vaga.component.scss"],
})
export class ConfirmationDialogComponent
  extends AppComponentBase
{
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { vaga: VagaDto; candidato: CandidatoDto },
    private _candidatoVagaService: CandidatoVagaServiceProxy,
    private router: Router,
    private _candidatoService: CandidatoServiceProxy,
    private serviceCanditadoOptions: CandidatoService,
    private _modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private _tokenService: TokenService,
    injector: Injector
  ) {
    super(injector);
  }

  createCandidatoVaga: CreateCandidatoVagaDto = new CreateCandidatoVagaDto();
  isInscrito: boolean = false;
  mensagem: string = `Deseja mesmo candidatar-se à vaga "${this.data.vaga.tituloVaga}"?`;

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.isInscrito = true;
    if (this.data.vaga.pcd) {
      this.mensagem = "Essa vaga é exclusiva para pessoa com deficiência. Deseja se inscrever?";
    } else {
      this.verifyLoggedIn();
    }
  }

  onYesClickPcd(){

    this.verifyLoggedIn();
  }

  realizarCandidatura() {
    abp.ui.setBusy();
    if (
      this.data.candidato.curriculoDadoPessoal != undefined &&
      this.data.candidato.curriculoDadoPessoal.corRacaString &&
      this.data.candidato.rendaBrutaFamiliarEnum > 8 &&
      ((this.data.candidato.curriculoDadoPessoalAnexo != undefined &&
        this.data.candidato.curriculoDadoPessoalAnexo.arquivoUrl.length > 0) ||
        this.data.candidato.curriculosExperiencias?.length > 0 ||
        this.data.candidato.candidatosFormacoesAcademicas?.length > 0 ||
        this.data.candidato.candidatosIdiomas?.length > 0 ||
        this.data.candidato.curriculosCompetencias?.length > 0)
    ) {
      this.createCandidatoVaga.candidatoId = this.data.candidato.id;
      this.createCandidatoVaga.vagaId = this.data.vaga.id;
      this._candidatoVagaService
        .create(this.createCandidatoVaga)
        .pipe(
          finalize(() => {
            abp.ui.clearBusy();
          })
        )
        .subscribe((x) => {
          this.data.candidato.candidatosVagas.push(x);
          this.serviceCanditadoOptions.setCandidato(this.data.candidato);

          abp.notify.success(this.l("SavedSuccessfully"));

          this.router.navigate(["/"]);
          abp.ui.clearBusy();
        });
    } else {
      this._candidatoService
        .tentativaCandidatarVaga(this.data.vaga.id)
        .pipe(
          finalize(() => {
            abp.ui.clearBusy();
          })
        )
        .subscribe((x) => {
          this.data.candidato = x;
          this.serviceCanditadoOptions.setCandidato(this.data.candidato);
          abp.notify.warn(
            this.l(
              "Antes de se candidatar à vaga, por favor, preencha seu currículo. Estamos ansiosos para saber mais sobre você!"
            )
          );
          if (this.data.candidato.rendaBrutaFamiliarEnum <= 8) {
            this.router.navigate(["/candidato/perfil"]);
          } else {
            this.router.navigate(["/candidato/meu-curriculo"]);
          }
        });
    }
  }

  private openLoginOrCreateSelectionModal(): void {
    let loginOrCreateSelectionDialog: BsModalRef;
    loginOrCreateSelectionDialog = this._modalService.show(
      LoginOrCreateSelectionDialogComponent,
      {
        class: "modal-dialog-centered",
        backdrop: true,
        ignoreBackdropClick: false,
      }
    );

    this.bsModalRef.hide();

    loginOrCreateSelectionDialog.content.optionSelected.subscribe(
      (option: string) => {
        if (option == "Fazer Login") {
          this.openLoginAccountDialog();
        } else if (option == "Criar Conta") {
          this.openCreateAccountDialog();
        }
      }
    );
  }

  private openLoginAccountDialog(): void {
    let loginAccountDialog: BsModalRef;

    loginAccountDialog = this._modalService.show(LoginDialogComponent, {
      class: "modal-lg",
      ignoreBackdropClick: true,
      initialState: {
        openedByDialogCandidatarVaga: true,
        vaga: this.data.vaga,
      },
    });
  }

  private openCreateAccountDialog(): void {
    let createAccountDialog: BsModalRef;

    createAccountDialog = this._modalService.show(
      CreateAccountDialogComponent,
      {
        class: "modal-lg",
        ignoreBackdropClick: true,
        initialState: {
          openedByDialogCandidatarVaga: true,
          vaga: this.data.vaga,
        },
      }
    );
  }

  isLoggedIn(): boolean {
    const token = this._tokenService.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  verifyLoggedIn(){
    if (this.isLoggedIn()) {
      this.dialogRef.close(true);
      this.realizarCandidatura();
    } else{
      this.dialogRef.close(true);
      this.openLoginOrCreateSelectionModal();
    }
  }

  private isTokenExpired(token: string): boolean {
    const tokenPayload = this.decodeToken(token);

    if (tokenPayload && tokenPayload.exp) {
      const expirationDateSeconds = tokenPayload.exp;

      const expirationDateMilliseconds = expirationDateSeconds * 1000;

      const currentTimestampMilliseconds = new Date().getTime();

      return expirationDateMilliseconds < currentTimestampMilliseconds;
    }

    return true;
  }

  private decodeToken(token: string): any {
    try {
      const tokenPayloadBase64 = token.split(".")[1];
      const tokenPayloadJson = atob(tokenPayloadBase64);
      return JSON.parse(tokenPayloadJson);
    } catch (error) {
      return null;
    }
  }
}
