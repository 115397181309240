import { ChangeDetectorRef, Component, Injector, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import {
  VagaServiceProxy,
  CargoServiceProxy,
  EnumServiceProxy,
  EnumDto,
  EnumDtoListResultDto
} from '@shared/service-proxies/service-proxies';
import { EnumNames } from '../../../../enums/enum-names';

import { Filtro } from '../../../../shared/components/filtro/models/filtro.model';
import { FiltroService } from '../../../../shared/components/filtro/service/filtro.service';
import { AppComponentBase } from '@shared/app-component-base';
import { JsonDataService } from '@shared/services/json-data.service';
import { Subscription } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: "filtro-vitrine",
  templateUrl: './filtro-vitrine.component.html',
  styleUrls: ["./filtro-vitrine.component.scss"],
  animations: [appModuleAnimation()]
})
export class FiltroVitrineComponent extends AppComponentBase implements OnInit {

  filtro: Filtro;

  estados: any[];
  cidades: any[];
  selectedEstado: string;
  selectedCidade: string;
  nomeEstadoSelecionado: string;
  idEstadoSelecionado: string;
  nomeCidadeSelecionada: string;
  desabilitarCidade = true;

  cargos: any[];
  selectedCargos: string[];


  listaRegimeTrabalho: EnumDto[] = [];
  listaModeloTrabalho: EnumDto[] = [];
  regimeTrabalho: any;
  modeloTrabalho: any;

  salario = 0;
  max = 0;
  min = 0;

  autoTicks = false;
  disabled = false;
  invert = false;
  showTicks = false;
  step = 1;
  thumbLabel = true;
  vertical = false;
  tickInterval = 1;

  nomeEmpresa:string = "";
  pcd:boolean = false;

  pcdTitle: string = this.l("Marque para exibir vagas destinadas à pessoas com deficiência.");

  constructor(
    injector: Injector,
    private _vagaService: VagaServiceProxy,
    private _modalService: BsModalService,
    private cdr: ChangeDetectorRef,
    public filtroService: FiltroService,
    private dataService: JsonDataService,
    private _cargoService: CargoServiceProxy,
    private _enumServiceProxy: EnumServiceProxy
  ) {
    super(injector);
  }
  ngOnInit(): void {
    this.loadEstados();
    this.loadCargos();
    this.getListRegimeTrabalho();
    this.getListModeloTrabalho();
    this.getSalarioMaisAlto();
    this.cdr.markForCheck();
  }

  loadEstados() {
    this.dataService.getEstados().subscribe(estados => {
      this.estados = estados.map((estado: any) => ({
        item_id: estado.Sigla,
        item_text: estado.Nome,
      }));
    });

    this.filtroService.getFiltroUpdateListener().subscribe(filtro => {
      this.filtro = filtro;
    });
  }

  loadCargos() {
    abp.ui.setBusy();
    this._cargoService.getAll()
      .pipe(
        finalize(() => {
          abp.ui.clearBusy();
        })
      )
      .subscribe(x => {
        if (x.length > 0) {
          this.cargos = x.map((carg: any, index: number) => ({
            item_id: carg.id,
            item_text: carg.nome,
          }));
        }
      });
  }

  private getListRegimeTrabalho(): Subscription {
    return this._enumServiceProxy
      .getByEnumName(EnumNames.REGIME_TRABALHO_ENUM)
      .subscribe((result: EnumDtoListResultDto) => {
        this.listaRegimeTrabalho = result.items;
      });
  }

  private getListModeloTrabalho(): Subscription {
    return this._enumServiceProxy
      .getByEnumName(EnumNames.MODELO_TRABALHO_ENUM)
      .subscribe((result: EnumDtoListResultDto) => {
        this.listaModeloTrabalho = result.items;
      });
  }

  getSalarioMaisAlto() {
    abp.ui.setBusy();
    this._vagaService.getSalarioMaisAlto()
      .pipe(
        finalize(() => {
          abp.ui.clearBusy();
        })
      )
      .subscribe(x => {
        this.max = x;
      });
  };

  onSlideTogglePcdChange(event: MatSlideToggleChange) {
    this.filtroService.setPcd(event.checked);
  }

  onEstadoSelected(item: any) {
    this.idEstadoSelecionado = this.dataService.getEstadoId(item['item_id']);
    this.nomeEstadoSelecionado = this.dataService.getEstadoSigla(this.idEstadoSelecionado);

    this.dataService.getCidades().subscribe(cidades => {
      var cidadesFiltradas = cidades.filter(cidade => cidade.Estado === this.idEstadoSelecionado);
      this.cidades = cidadesFiltradas.map((city: any, index: number) => ({
        item_id: city.ID,
        item_text: city.Nome,
      }));
    });
    this.filtroService.setEstado(this.nomeEstadoSelecionado);
    this.desabilitarCidade = false;
  }

  onCidadeSelected(item: any) {
    this.dataService.selecionarCidade(item['item_id']);
    this.filtroService.setCidade(item['item_text']);
  }

  onEstadoDeSelected(item: any) {
    this.desabilitarCidade = true;
    this.selectedCidade = undefined;
    this.filtroService.setEstado(undefined);
  }

  onCidadeDeSelected(item: any) {
    this.filtroService.setCidade(undefined);
  }

  onCargoSelected(item: any) {
    var cargosId = this.selectedCargos.map((e) => e['item_id']);
    this.filtroService.setCargos(cargosId);
    this.cdr.markForCheck();
  }

  onCargoDeSelected(item: any) {
    if (this.selectedCargos.length > 0) {
      var cargosId = this.selectedCargos.map((e) => e['item_id']);
      this.filtroService.setCargos(cargosId);
      this.cdr.markForCheck();
    } else {
      this.filtroService.setCargos(undefined);
    }
  }

  onCargoSelectedAll() {
    var cargosId = this.selectedCargos.map((e) => e['item_id']);
    this.filtroService.setCargos(cargosId);
    this.cdr.markForCheck();
  }

  onCargoDeSelectedAll() {
    this.filtroService.setCargos(undefined);
    this.cdr.markForCheck();
  }

  atualizarSalario() {
    this.filtroService.setSalario(this.salario);
  }

  sliderSalarioChanged() {
    this.filtroService.setSalario(this.salario);
  }

  selecaoModeloChange() {
    if (this.modeloTrabalho === 'todas') {
      this.filtroService.setModelo(undefined);
    } else {
      this.filtroService.setModelo(this.modeloTrabalho);
    }
  }

  dropdownSettings = {
    singleSelection: true,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    searchPlaceholderText: this.l('Pesquisar'),
    noDataLabel: "Sem dados disponíveis",
    enableSearchFilter: true,
    disabled: false,
    badgeShowLimit: 3,
    noFilteredDataAvailablePlaceholderText:'Nenhum dado filtrado disponível'
  };

  dropdownSettingsCargo = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Selecionar todos',
    unSelectAllText: 'Desmarca todos',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    searchPlaceholderText: this.l('Pesquisar'),
    noDataLabel: "Sem dados disponíveis",
    enableSearchFilter: true,
    disabled: false,
    badgeShowLimit: 3,
    noFilteredDataAvailablePlaceholderText:'Nenhum dado filtrado disponível'
  };
  getSliderTickInterval(): number | 'auto' {
    if (this.showTicks) {
      return this.autoTicks ? 'auto' : this.tickInterval;
    }


    return 0;
  }

  OnNgModelChangeEmpresa($event)
  {
    this.filtroService.setNomeEmpresa($event);
    this.cdr.markForCheck();
  }

  formatLabel(value: number): string {
    if (value >= 1000) {
      return value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
    }
    return value.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  }
}
