<div [@routerTransition] class="paginaCompleta"  [ngClass]="{'m-header': !appSession.userId}">
    <div class="container py-10">
        <div class="col-12 col px-5 mb-5">
            <div class="row justify-content-between align-items-center">
                <div class="col-6">
                    <h1 class="title">Detalhes</h1>
                </div>

                <button *ngIf="!isInscrito" type="button" class="btn btn-primary col-6 px-6 py-3 w-25 btn-candidate"
                    (click)="candidatar()">
                    <span class="subtitle-button">Candidatar-se</span>
                </button>

                <div class="col-6">
                    <img class="img-inscrito" *ngIf="isInscrito" src="../../../../../assets/img//candidatou.png"
                        alt="inscrito" />
                </div>
            </div>
        </div>
        <mat-divider class="mb-5"></mat-divider>
        <div class="col d-flex align-items-center mb-5">

            <div class="square-image ml-2">
                <img *ngIf="!vaga.confidencial" class="perfil-image"
                    [src]="vaga.empresa?.urlFotoPerfil != undefined || vaga.empresa?.urlFotoPerfil != null ? vaga.empresa?.urlFotoPerfilDesbloqueado : '../../assets/img/user-void.png'"
                    alt="perfil-image" />

                <img *ngIf="vaga.confidencial" class="perfil-image" src="../../assets/img/vaga.png"
                    alt="perfil-image" />
            </div>

            <div class="p-2 flex-grow-1">
                <div class="row justify-content-between" style="min-height: 80px;">
                    <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xl-3">
                        <div *ngIf="vaga.empresa != undefined && !vaga.confidencial">
                            <p class="text-muted mb-1" style="font-size: 15px; font-weight: 300;">Empresa</p>
                            <h1 class="title-2 mb-2">{{ vaga.empresa.nome }}</h1>
                        </div>
                        <div class="mt-1 img-conf" *ngIf="vaga.confidencial">
                            <img src="../../assets/img/confidencial.png" alt="vaga confidencial" width="160" />
                        </div>
                        <p *ngIf="!vaga.confidencial" class="align-self-start subtitle">
                            {{ vaga.cidade }} - {{ vaga.estado }}
                        </p>
                    </div>
                    <div *ngIf="!vaga.confidencial" class="col-sm-12 col-md-6">
                        <!-- <div class="row mb-5 align-items-center">
                            <div class="col-md-2">
                                <span class="flex-grow-1 subtitle px-2">Contato:</span>
                            </div>
                            <div class="col">
                                <div class="row">
                                    <div>
                                        <span class="title-3">{{vaga.empresa?.email}}</span>
                                    </div>
                                    <div>
                                        <span class="title-3">{{telefoneFormatado}}</span>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div *ngIf="vaga.empresa && vaga.empresa.endereco" class="row">
                            <div class="col-md-2">
                                <span class="flex-grow-1 subtitle px-2">Endereço:</span>
                            </div>
                            <div class="col">
                                <span class="title-3">{{vaga.empresa?.endereco?.logradouro}},
                                    {{vaga.empresa?.endereco?.bairro}}, nº {{vaga.empresa?.endereco?.numero}},
                                    {{vaga.empresa?.endereco?.cidade}}/{{vaga.empresa?.endereco?.estado}}
                                </span>
                                <span class="title-3" *ngIf="vaga.empresa?.endereco?.complemento">
                                    , {{vaga.empresa?.endereco?.complemento}}.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <mat-divider class="mb-5"></mat-divider>
        <div class="d-flex justify-content-center">
            <div class="col-md-7">
                <div class="text-center mb-10">
                    <p class="title-soft">Localização da vaga</p>
                </div>
                <div class="mb-10">
                    <p class="subtitle">Localização</p>
                    <div class="form-group mb-0 col">
                        <label class="col-form-label subtitle-2">Estado</label>
                        <input type="text" class="form-control" name="estado" id="estado" maxlength="256"
                            placeholder="Desenvolvedor Flutter..." [(ngModel)]="vaga.estado" #estadoModel="ngModel"
                            disabled="true" #estadoEl />
                    </div>
                    <div class="form-group mb-0 col">
                        <label class="col-form-label subtitle-2">Cidade</label>
                        <input type="text" class="form-control" name="cidade" id="cidade" maxlength="256"
                            placeholder="Desenvolvedor Flutter..." [(ngModel)]="vaga.cidade" #cidadeModel="ngModel"
                            disabled="true" #cidadeEl />
                    </div>
                </div>
                <div class="text-center mb-10">
                    <p class="title-soft">Dados sobre a vaga</p>
                </div>
                <div class="container-dados mb-10">
                    <div class="row">
                        <div class="form-group col">
                            <label class="col-form-label subtitle-2">Título</label>
                            <div>
                                <input type="text" class="form-control" name="nomeVaga" id="nomeVaga" maxlength="256"
                                    placeholder="Desenvolvedor Flutter..." [(ngModel)]="vaga.tituloVaga"
                                    #nomeVagaModel="ngModel" disabled="true" #nomeVagaEl />
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-between">
                        <div class="form-group col-md-5">
                            <label class="col-form-label subtitle-2">Data Início</label>
                            <input type="text" class="form-control" name="dataInicio" id="dataInicio" maxlength="256"
                                [value]="vaga.dataInicio | date:'dd/MM/yyyy'" disabled="true" #dataInicioEl />
                        </div>
                        <div class="form-group col-md-5">
                            <label class="col-form-label subtitle-2">Data Término</label>
                            <input type="text" class="form-control" name="dataTermino" id="dataTermino" maxlength="256"
                                [value]="vaga.dataTermino | date:'dd/MM/yyyy'" disabled="true" #dataInicioEl />
                        </div>
                    </div>
                    <div class="row justify-content-between mt-5">
                        <div class="form-group col-md-5">
                            <label class="col-form-label subtitle-2">Modelo de trabalho</label>
                            <input type="text" class="form-control" name="modeloTrabalho" id="modeloTrabalho"
                                maxlength="256" [value]="vaga.modeloTrabalhoString" disabled="true" #modeloTrabalhoEl />
                        </div>
                        <div class="form-group col-md-5">
                            <div>
                                <label class="col-form-label subtitle-2">Valor salário</label>
                                <input [disabled]="true" type="text" class="form-control" name="valorSalario"
                                    id="valorSalario" maxlength="256" [(ngModel)]="vaga.salario"
                                    #valorSalarioModel="ngModel" #valorSalarioEl placeholder="0,00" currencyMask
                                    [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }" />
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-between mt-5">
                        <div class="form-group col-md-5">
                            <label class="col-form-label subtitle-2">Horas semanais</label>
                            <input type="text" class="form-control" name="horasSemanais" id="horasSemanais"
                                placeholder="40" maxlength="256" [(ngModel)]="vaga.horasSemanais" mask="9999999999"
                                #horasSemanaisModel="ngModel" [disabled]="true" #horasSemanaisEl />
                        </div>
                        <div class="form-group col-md-5">
                            <label class="col-form-label subtitle-2">Cargo</label>
                            <input type="text" class="form-control" name="cargo" id="cargo" placeholder="40"
                                maxlength="256" [value]="vaga.cargo?.nome" [disabled]="true" #cargoEl />
                        </div>
                    </div>
                    <div class="row justify-content-between mt-5">
                        <div class="form-group col-md-5">
                            <label class="col-form-label subtitle-2">Regime de trabalho</label>
                            <input type="text" class="form-control" name="modeloTrabalho" id="modeloTrabalho"
                                maxlength="256" [value]="vaga.regimeTrabalhoString" disabled="true" #modeloTrabalhoEl />
                        </div>
                        <div class="form-group col-md-5">
                            <label class="col-form-label subtitle-2">Número de vagas</label>
                            <input type="text" class="form-control" name="quantidadeVagas" id="quantidadeVagas"
                                maxlength="256" [(ngModel)]="vaga.quantidadeVagas" placeholder="02" mask="9999999999"
                                #quantidadeVagasModel="ngModel" disabled="true" #quantidadeVagasEl />
                            <abp-validation-summary [control]="quantidadeVagasModel"
                                [controlEl]="quantidadeVagasEl"></abp-validation-summary>
                        </div>
                    </div>
                </div>
                <div class="text-center mb-10">
                    <p class="title-soft">Descrição</p>
                </div>
                <div class="row mb-10">
                    <div class="col">
                        <div class="card-anuncio-inside px-10 py-7">
                            <p class="normal-text" [innerHTML]="vaga.descricao"></p>
                        </div>
                    </div>
                </div>
                <div *ngIf="vaga.competencias && vaga.competencias.length > 0">
                    <div class="text-center mb-5">
                        <p class="title-soft">Competência(s)</p>
                    </div>
                    <div class="card-body table-responsive p-0">
                        <table class="table text-nowrap">
                            <thead class="tabela">
                                <tr>
                                    <th>Nomes</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let competencia of vaga.competencias">
                                    <td>
                                        <ng-container>
                                            {{ competencia?.nome }}
                                        </ng-container>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
