<div class="card-anuncio mb-6 p-5">
    <div>
        <div class="col-12">
            <div class="row mt-3 align-items-center">
                <div class="col-auto">
                    <i class="fa-solid fa-clipboard maleta-icon" style="font-size: 20px;"></i>
                </div>
                <div class="col">
                    <h3 class="title-vaga">
                        {{ vaga.tituloVaga }}
                    </h3>
                </div>
                <div *ngIf="isInscrito" class="col">
                    <h3 class="title-vaga">
                        <img class="img-inscrito" src="../../../../assets/img/candidatou.png" alt="candidatou-se">
                    </h3>
                </div>
            </div>

            <div class="row mt-2" *ngIf="vaga.pcd == true">
                <div class="col-auto">
                    <img width="30px" class="selo" src="../../../../assets/img/pcd_icone.png" alt="PCD">
                </div>
                <div class="col">
                    <h3 class="title-vaga">
                        Vaga para PcD<span class="icon-container ml-2 align-self-center" [title]="pcdTitle">
                            <i class="fa fa-question-circle custom-icon"></i></span>
                    </h3>
                </div>
            </div>

        </div>

        <div class="col d-flex align-items-center mt-5 mb-5">

            <div class="col-6 square-image ml-2 d-block">
                <img *ngIf="!vaga.confidencial" class="perfil-image"
                    [src]="vaga.empresa?.urlFotoPerfilDesbloqueado != undefined ? vaga.empresa?.urlFotoPerfilDesbloqueado : '../../assets/img/user-void.png'"
                    alt="perfil-image" />

                <img *ngIf="vaga.confidencial" class="perfil-image" src="../../assets/img/vaga.png"
                    alt="perfil-image" />
            </div>

            <div class="col p-2 flex-grow-1">
                <div class="row justify-content-between" style="min-height: 80px;">
                    <div class="col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xl-3">
                        <div *ngIf="!vaga.confidencial">
                            <p class="text-muted mb-1" style="font-size: 15px; font-weight: 300;">Empresa</p>
                            <h1 class="title-2 mb-2">{{ vaga.empresa.nome }}</h1>
                        </div>
                        <div class="mt-1" *ngIf="vaga.confidencial">
                            <img src="../../assets/img/confidencial.png" alt="vaga confidencial" width="160" />
                        </div>
                        <p class="align-self-start cidade-nome">
                            {{ vaga.cidade }} - {{ vaga.estado }}
                        </p>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6 col-xl-3 col-xl-3">
                        <p class="text-muted mb-1" style="font-size: 15px; font-weight: 300;">Cargo</p>
                        <h1 class="title-2 mb-2">{{ vaga.cargo.nome }}</h1>
                        <p class="align-self-start subtitle vaga-salario-text"
                            style="font-size: 14px; font-weight: 300; color: #242424 !important;">
                            <span *ngIf="vaga.exibirVagas">Vagas {{ vaga.quantidadeVagas }}</span>
                            <span *ngIf="vaga.salario && !vaga.exibirVagas"> Salário - {{ vaga.salario |
                                currency:'BRL':'symbol':'1.2-2' }}</span>
                            <span *ngIf="vaga.salario && vaga.exibirVagas"> - {{ vaga.salario |
                                currency:'BRL':'symbol':'1.2-2' }}</span>
                        </p>
                    </div>
                </div>
                <mat-divider id="divider-web" class="pl-2"></mat-divider>
            </div>

        </div>
        <mat-divider id="divider-app"></mat-divider>
        <div class="row align-items-start mb-5 mt-5">

            <div class="col-md-6 mb-5">
                <p class="subtitle">Descrição da vaga</p>
                <div class="card-anuncio-inside p-5 min-height-card">
                    <div [innerHTML]="truncateText(vaga.descricao, 600)"></div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="row justify-content-center mb-4">
                    <div class="col-12 ml-4">
                        <div class="d-flex align-items-center">
                            <div class="square-image-icon">
                                <img src="assets/media/svg/icons/Empresa/maleta.svg" alt="maleta-image"
                                    class="maleta-image">
                            </div>
                            <span class="flex-grow-1 subtitle px-2">Regime de trabalho</span>
                        </div>
                    </div>

                    <div class="col-12 pt-2 pl-6 ml-6">
                        <ul class="list-item-dot ">
                            <li>{{ vaga.horasSemanais }} Horas Semanais</li>
                            <li>{{ vaga.modeloTrabalhoString }}</li>
                            <li>{{ vaga.regimeTrabalhoString }}</li>
                        </ul>
                    </div>
                </div>

                <div class="row justify-content-center mb-2">

                    <div class="col-12 ml-4">
                        <div class="d-flex align-items-center">
                            <div class="square-image-icon">
                                <img src="assets/media/svg/icons/Empresa/certificados.svg" alt="maleta-image"
                                    class="maleta-image">
                            </div>
                            <span class="flex-grow-1 subtitle px-2">Competências</span>
                        </div>
                    </div>

                    <div class="col-12 pt-2 pl-6 ml-6">
                        <ul class="list-item-dot">
                            <ng-container *ngFor="let competencia of vaga.competencias; let i = index">
                                <li *ngIf="i < 3">{{ competencia.nome }}</li>
                            </ng-container>
                            <li *ngIf="vaga.competencias.length > 3">e mais {{ vaga.competencias.length - 3 }}...</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-end d-flex gap-3">
            <button type="button" class="botao-detalhes col-md-7 col-xs-12 px-6 py-3 mat-elevation-z3"
                (click)="detalhes()">
                Ver detalhes
            </button>
            <button *ngIf="!isInscrito" type="button" class="botao-candidatar col-md-7 col-xs-12 px-6 py-3 mat-elevation-z3"
                (click)="candidatar()">
                Candidatar-se
            </button>
        </div>
    </div>
</div>
