import { ChangeDetectorRef, Component, Injector, Input } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { PagedListingComponentBase, PagedRequestDto } from 'shared/paged-listing-component-base';
import { VagaDto, VagaServiceProxy, VagaDtoPagedResultDto, CandidatoDto, CandidatoVagaServiceProxy, CandidatoVagaDto } from '@shared/service-proxies/service-proxies';
import { Filtro } from 'shared/components/filtro/models/filtro.model';
import { Subscription } from 'rxjs';
import { FiltroService } from '@shared/components/filtro/service/filtro.service';
import { CandidatoService } from 'candidato/service/candidato.service';
import { TokenService } from 'abp-ng2-module';

class PagedVagaRequestDto extends PagedRequestDto {
  keyword: string;
}

@Component({
  selector: "vitrine-vagas",
  templateUrl: './vitrine.component.html',
  styleUrls: ["./vitrine.component.scss"],
  animations: [appModuleAnimation()]
})
export class VitrineComponent extends PagedListingComponentBase<VagaDto> {

  listaVagas: VagaDto[] = [];
  inscricoes: CandidatoVagaDto[] = [];
  public filtro: Filtro;
  private filtroSub: Subscription = new Subscription();
  candidatoDto: CandidatoDto;
  private candidatoSub: Subscription = new Subscription();
  standalone: true;
  show = true;
  keyword = '';
  protected previousFilters: any = {};
  @Input() vagaBusca: string;

  constructor(
    injector: Injector,
    private _vagaService: VagaServiceProxy,
    private _candidatoVagaService: CandidatoVagaServiceProxy,
    private cdr: ChangeDetectorRef,
    public filtroService: FiltroService,
    public candidatoService: CandidatoService,
    private _tokenService: TokenService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.filtro = this.filtroService.getFiltro();
    this.filtroSub = this.filtroService.getFiltroUpdateListener()
      .subscribe((filtro: Filtro) => {
        this.filtro = filtro;
        this.refresh();
      });
    if (this.isLoggedIn()) {
      this.initCandidato();
      if (this.candidatoDto != undefined) {
        this.getInscricoes(this.candidatoDto.id);
      }
    }
  }

  isLoggedIn(): boolean {
    const token = this._tokenService.getToken();
    return !!token && !this.isTokenExpired(token);
  }

  private isTokenExpired(token: string): boolean {
    const tokenPayload = this.decodeToken(token);

    if (tokenPayload && tokenPayload.exp) {
      const expirationDateSeconds = tokenPayload.exp;

      const expirationDateMilliseconds = expirationDateSeconds * 1000;

      const currentTimestampMilliseconds = new Date().getTime();

      return expirationDateMilliseconds < currentTimestampMilliseconds;
    }

    return true;
  }

  private decodeToken(token: string): any {
    try {
      const tokenPayloadBase64 = token.split('.')[1];
      const tokenPayloadJson = atob(tokenPayloadBase64);
      return JSON.parse(tokenPayloadJson);
    } catch (error) {
      return null;
    }
  }

  async initCandidato(): Promise<void> {
    this.candidatoDto = this.candidatoService.getCandidato();
    this.candidatoSub = this.candidatoService.getCandidatoUpdateListener()
      .subscribe((candidat: CandidatoDto) => {
        this.candidatoDto = candidat;
        this.cdr.markForCheck();
        this.getInscricoes(candidat.id);
      });
  }

  protected list(
    request: PagedVagaRequestDto,
    pageNumber: number,
    finishedCallback: Function
  ): void {
    const currentFilters = {
      keyword: this.vagaBusca == undefined ? this.filtro?.textoBusca : this.vagaBusca,
      empresaId: this.filtro?.empresaId,
      cargosId: this.filtro?.cargosId,
      competenciasId: this.filtro?.competenciasId,
      encerrada: this.filtro?.encerrada,
      naoIniciada: this.filtro?.naoIniciada,
      dataInicio: this.filtro?.dataInicio,
      dataTermino: this.filtro?.dataTermino,
      estado: this.filtro?.estado,
      cidade: this.filtro?.cidade,
      modeloTrabalho: this.filtro?.modeloTrabalho,
      regimeTrabalho: this.filtro?.regimeTrabalho,
      salario: this.filtro?.salario,
      ordenacao: this.filtro?.ordenacao,
      nomeEmpresa: this.filtro?.nomeEmpresa,
      cnpjEmpresa: this.filtro?.cnpjEmpresa,
      pcd: this.filtro?.pcd,
      microrregioes: this.filtro?.microrregioes,
    };
    this.vagaBusca = undefined;
    let filtersChanged = false;
    for (const key in currentFilters) {
      if (currentFilters[key] !== this.previousFilters[key]) {
        filtersChanged = true;
        break;
      }
    }

    if (filtersChanged) {
      pageNumber = 1;
      request.skipCount = 0;
      this.previousFilters = { ...currentFilters };
    } else {
      request.skipCount = (pageNumber - 1) * request.maxResultCount;
    }

    request.keyword = currentFilters.keyword;

    abp.ui.setBusy();

    this._vagaService
      .getAllPaged(
        request.keyword,
        currentFilters.empresaId,
        currentFilters.cargosId,
        currentFilters.competenciasId,
        currentFilters.encerrada,
        currentFilters.naoIniciada,
        currentFilters.dataInicio,
        currentFilters.dataTermino,
        currentFilters.estado,
        currentFilters.cidade,
        currentFilters.modeloTrabalho,
        currentFilters.regimeTrabalho,
        currentFilters.salario,
        currentFilters.ordenacao,
        currentFilters.nomeEmpresa,
        currentFilters.cnpjEmpresa,
        currentFilters.pcd,
        currentFilters.microrregioes,
        request.skipCount,
        request.maxResultCount
      )
      .pipe(
        finalize(() => {
          finishedCallback();
          abp.ui.clearBusy();
        })
      )
      .subscribe((result: VagaDtoPagedResultDto) => {
        this.listaVagas = result.items;
        this.showPaging(result, pageNumber);
        this.cdr.markForCheck();
      });
  }

  protected delete(vaga: VagaDto): void { }

  getInscricoes(id) {
    abp.ui.setBusy();
    this._candidatoVagaService.getInscricoesCanditado(id).pipe(
      finalize(() => {
        abp.ui.clearBusy();
      })
    ).subscribe((inscricoes) => {
      this.inscricoes = inscricoes;
    })
  }

  isInscrito(vaga: VagaDto) {
    if (this.inscricoes.length > 0) {
      return this.inscricoes.some(inscricao =>
        inscricao.vagaId === vaga.id && inscricao.candidatoId === this.candidatoDto.id
      );
    }
    else {
      return false;
    }
  }
}
