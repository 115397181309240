import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class VerificarRotaService {

  constructor( 
    private _router: Router,
    private _routerActive: ActivatedRoute
  ) { }

  checkRoute(): boolean {
    const url = this._router.url; 
    if(url.includes('para-empresas') || url.includes('quem-somos-empresa')){
     return true;
    }else{
      return false;
    }
  }
}
